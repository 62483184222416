import { ActionContext } from 'vuex';
import { CabinetState, RootState, api } from '@/store';
import { q } from '@/store/api';

const query = q`
  query loadCompany ($companyId: ID, $contractId: ID, $partnerId: ID) {
    cabinet {
      company(id: $companyId, contractId: $contractId, partnerId: $partnerId) {
        id name brand companyType type role b2b
        officialAddress postAddress inn kpp ogrn
        contact director phone email
        buh { name phone email }
        tech { name phone email }
        status
        mode
        simple
        payments { balance needMoney count }
        drivers { hasAny }
        contract { id number bank bik account correspondence discount balanceLimit type }
        reportType
        partner { id name parent { id name } }
        partners { id name }
        userAccess {
          partnerParentId
          partnerId
          name
          role
        }
      }
    }
  }
`;

export default (
  {
    rootState: {
      auth: { token },
    },
    commit,
  }: ActionContext<CabinetState, RootState>,
  { companyId, contractId, departmentId }: { companyId?: string; contractId?: string; departmentId?: string },
) => {
  commit('isRequestInProgress', true);
  return api
    .graphql({
      query,
      variables: {
        companyId,
        contractId,
        partnerId: departmentId,
      },
      token,
    })
    .then(({ cabinet }) => {
      if (cabinet?.company) {
        commit('setCompany', {
          ...cabinet.company,
          hasDrivers: !!cabinet.company.drivers?.hasAny,
        });
      }
      if (cabinet?.company?.partner) {
        commit('department', cabinet?.company?.partner);
      }
      return cabinet?.company;
    })
    .finally(() => {
      commit('isRequestInProgress', false);
    });
};
