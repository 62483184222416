import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '@/layouts/HomeView.vue';
import CabinetView from '@/layouts/CabinetView.vue';
import ControlView from '@/layouts/ControlView.vue';
import MapsView from '@/layouts/MapsView.vue';
import StationsMapView from '@/views/StationsMapView.vue';
import MonthReportView from '@/views/reports/MonthReportView.vue';
import { LimitsType, LogType } from '@/gql/graphql';

export const ROUTE_PARAMS_TYPE = {
  company: 'company',
  station: 'station',
  department: 'department',
} as const;

export const ROUTE_NAME = {
  departments: 'departments',
  subdepartments: 'subdepartments',
  balance: 'balance',
  partners: 'partners',
  reports: 'reports',
};

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/stations-map',
    name: 'stations-map',
    component: StationsMapView,
    meta: {
      public: true,
    },
  },
  {
    path: '/registration',
    name: 'registration',
    component: HomeView,
  },
  {
    path: '/sbidAuth',
    name: 'sbidAuth',
    component: () => import('@/modules/SbidAuthPage.vue'),
    meta: {
      public: true,
    },
  },
  {
    path: '/sbidReturn',
    name: 'sbidReturn',
    component: HomeView,
  },
  {
    path: '/:type(company|station|department)/:id([\\da-f]{8}-[\\da-f]{4}-[\\da-f]{4}-[\\da-f]{4}-[\\da-f]{12})/',
    components: {
      default: CabinetView,
    },
    props: {
      default: true,
      side: true,
    },
    children: [
      // Заказы
      {
        path: 'orders',
        name: 'orders',
        component: () => import('@/modules/orders/index.vue'),
        props: true,
        meta: {
          label: 'Заказы',
          icon: 'nav/orders',
          sorting: 0,
          nav: {
            roles: ['admin', 'manager', 'support', 'sales', 'first_line_support'],
            types: ['internal', 'tk', 'taxi', 'aggregator', 'azs'],
          },
        },
      },
      {
        path: 'orders/:orderType/:orderId',
        name: 'order',
        props: true,
        component: () => import('@/modules/order/index.vue'),
      },
      {
        path: 'transactions',
        redirect: ({ params }) => ({ name: 'orders', params }),
      },

      {
        path: 'termsOfUse',
        name: 'termsOfUse',
        props: true,
        component: () => import('@/modules/TermsOfUsePage.vue'),
      },

      // Админ
      {
        name: 'corp-clients/online',
        path: 'admin/corp-clients/online',
        component: () => import('@/modules/admin/corpClients/index.vue'),
        props: { contractsType: 'online' },
        meta: {
          label: 'Корп. клиенты',
          icon: 'nav/corp',
          sorting: 3,
          nav: {
            roles: ['admin', 'support', 'first_line_support', 'sales'],
            types: ['internal'],
          },
        },
      },

      {
        name: 'corp-client',
        path: 'admin/corp-clients/online/:companyId',
        component: () => import('@/modules/admin/corpClient/index.vue'),
        props: true,
      },

      // Бизнес-карты
      {
        name: 'cards',
        path: 'cards',
        component: () => import('@/modules/BusinessCardsPage.vue'),
        props: true,
        meta: {
          label: 'Бизнес-карты',
          icon: 'nav/credit-card',
          sorting: 4,
          nav: {
            roles: ['admin'],
            types: ['tk'],
          },
        },
      },
      {
        name: 'card',
        path: 'cards/:token',
        component: () => import('@/modules/BusinessCardPage.vue'),
        props: true,
      },

      {
        path: 'paymentFinished',
        name: 'paymentFinished',
        component: () => import('@/modules/paymentFinishedPage.vue'),
        props: true,
      },

      // АЗС
      {
        name: 'stations',
        path: 'stations',
        component: () => import('@/modules/stations/index.vue'),
        props: true,
        meta: {
          label: 'АЗС',
          icon: 'nav/gas-station',
          sorting: 4,
          nav: {
            roles: ['admin'],
            types: ['aggregator', 'azs'],
          },
        },
      },
      {
        name: 'station',
        path: 'stations/:stationId',
        component: () => import('@/modules/station/index.vue'),
        props: true,
      },

      // админ АЗС
      {
        name: 'admin/azses',
        path: 'admin/azs',
        component: () => import('@/modules/admin/azses/index.vue'),
        meta: {
          label: 'АЗС',
          icon: 'nav/gas-station',
          sorting: 4,
          nav: {
            roles: ['admin', 'support', 'first_line_support', 'sales'],
            types: ['internal'],
          },
        },
      },
      {
        name: 'admin/azs',
        path: 'admin/azs/:companyId/:contractId?',
        component: () => import('@/modules/admin/azs/index.vue'),
        props: true,
      },
      {
        name: 'admin/azs/station',
        path: 'admin/azs/station/:companyId?/:contractId?/:stationId',
        component: () => import('@/modules/admin/station/index.vue'),
        props: true,
      },

      // Водители
      {
        name: 'drivers',
        path: 'drivers',
        component: () => import('@/modules/drivers/index.vue'),
        props: true,
        meta: {
          label: 'Водители',
          icon: 'nav/users',
          sorting: 4,
          nav: {
            roles: ['admin', 'manager', 'sales', 'support', 'first_line_support'],
            types: ['tk', 'taxi'],
          },
        },
      },
      {
        name: 'driver',
        path: 'drivers/:partnerId/:driverId',
        component: () => import('@/modules/driver/index.vue'),
        props: true,
      },

      {
        name: 'cars',
        path: 'cars',
        component: () => import('@/modules/cars/index.vue'),
        props: true,
        meta: {
          label: 'Автомобили',
          icon: 'nav/car',
          sorting: 8,
          nav: {
            roles: ['admin', 'manager', 'sales', 'support', 'first_line_support'],
            types: ['tk'],
          },
        },
      },

      {
        name: 'car',
        path: 'cars/:carId',
        component: () => import('@/modules/car/index.vue'),
        props: true,
      },

      // Оплата
      {
        path: 'balance',
        name: 'balance',
        component: () => import('@/modules/balance/index.vue'),
        props: true,
        meta: {
          label: 'Оплата',
          icon: 'nav/card',
          sorting: 5,
          nav: {
            roles: ['admin', 'sales', 'support', 'first_line_support'],
            types: ['tk', 'taxi', 'aggregator'],
          },
        },
      },

      // Путевые листы
      {
        name: 'waybills',
        path: 'waybills',
        component: () => import('@/modules/WaybillsPage.vue'),
        props: true,
        meta: {
          label: 'Путевые листы',
          icon: 'nav/info-circle',
          sorting: 6,
          nav: {
            roles: ['admin', 'support', 'first_line_support'],
            types: ['tk'],
          },
        },
      },
      {
        name: 'waybill',
        path: 'waybills/:waybillId',
        component: () => import('@/modules/WaybillPage.vue'),
        props: true,
      },

      {
        name: 'departments',
        path: 'departments',
        component: () => import('@/modules/departments/index.vue'),
        props: true,
        meta: {
          label: 'Отделы',
          icon: 'nav/departments',
          sorting: 8,
          nav: {
            roles: ['admin', 'manager', 'sales', 'support', 'first_line_support'],
            types: ['tk'],
          },
        },
      },

      {
        name: 'subdepartments',
        path: 'subdepartments',
        component: () => import('@/modules/departments/index.vue'),
        props: true,
        meta: {
          label: 'Подотделы',
          icon: 'nav/departments',
          sorting: 8,
          nav: {
            roles: ['admin', 'manager', 'sales', 'support', 'first_line_support'],
            types: ['tk'],
          },
        },
      },

      // Пользователи (админ)
      {
        name: 'control',
        path: 'control',
        component: ControlView,
        props: true,
        meta: {
          label: 'Управление',
          icon: 'nav/control',
          sorting: 6,
          nav: {
            roles: ['admin'],
            types: ['internal'],
            items: [
              { label: 'Приложение', item: 'control/faq' },
              { label: 'СберБизнесID', item: 'control/sberid' },
              { label: 'Мониторинг', item: 'control/monitoring' },
              { label: 'Отчёты', item: 'control/reports' },
            ],
          },
        },
        children: [
          {
            name: 'control/faq',
            path: 'faq',
            component: () => import('@/modules/admin/controlApplication/faq/index.vue'),
          },
          {
            name: 'control/sberid',
            path: 'sberid',
            component: () => import('@/modules/admin/controlApplication/sberid/index.vue'),
            props: true,
          },
          {
            name: 'control/monitoring',
            path: 'monitoring',
            component: () => import('@/modules/support/MonitoringPage.vue'),
          },
          {
            name: 'control/reports',
            path: 'reports',
            component: () => import('@/modules/admin/AdminReports.vue'),
            props: true,
          },
        ],
      },

      // Карта АЗС
      {
        path: 'map',
        name: 'map',
        component: MapsView,
        meta: {
          label: 'Карта АЗС',
          icon: 'nav/map-point',
          sorting: 6,
          nav: {
            roles: ['admin', 'manager', 'sales', 'support', 'first_line_support'],
            types: ['tk', 'taxi', 'aggregator'],
          },
        },
      },
      // Отчеты
      {
        path: 'reports',
        name: 'reports',
        component: () => import('@/modules/reports/index.vue'),
        props: true,
        meta: {
          label: 'Отчеты',
          icon: 'nav/reports',
          sorting: 7,
          nav: {
            roles: ['admin'],
            types: ['tk', 'taxi', 'aggregator', 'azs'],
          },
        },
      },
      {
        path: 'reports/:month(\\d\\d\\d\\d-\\d\\d)',
        name: 'report',
        component: MonthReportView,
        props: true,
        meta: {
          /*
           * label: 'Отчеты',
           * icon: 'nav/reports',
           * sorting: 7,
           * nav: { types: ['tk', 'azs'] },
           */
        },
      },
      // Настройка профиля
      {
        path: 'profile',
        name: 'profile',
        component: () => import('@/modules/profile/index.vue'),
        props: true,
        meta: {
          label: 'Профиль',
          icon: 'nav/settings',
          sorting: 8,
          nav: {
            roles: ['admin', 'manager', 'sales', 'support', 'first_line_support'],
            types: ['tk', 'taxi', 'aggregator', 'azs'],
          },
        },
      },

      // Бухгалтер
      {
        path: 'accountant/settlements',
        name: 'accountant/settlements',
        component: () => import('@/modules/accountant/settlements/index.vue'),
        props: true,
        meta: {
          label: 'Взаиморасчёты',
          icon: 'nav/integration',
          sorting: 1,
          nav: {
            roles: ['accountant'],
            types: ['internal'],
          },
        },
      },
      {
        path: 'accountant/settlement/:companyType/:companyId/:contractId',
        name: 'accountant/settlement',
        component: () => import('@/modules/accountant/settlement/index.vue'),
        props: true,
      },
      {
        name: 'accountant/invoices',
        path: 'accountant/invoices',
        component: () => import('@/modules/accountant/invoices/index.vue'),
        props: true,
        meta: {
          label: 'Исх. платежи',
          icon: 'nav/users',
          sorting: 2,
          nav: {
            roles: ['admin', 'accountant'],
            types: ['internal'],
          },
        },
      },
      {
        name: 'accountant/corp/online',
        path: 'accountant/corp/online',
        component: () => import('@/modules/accountant/companies/index.vue'),
        props: { companyType: 'corp', contractsType: 'online' },
        meta: {
          label: 'Корп. клиенты',
          icon: 'nav/corp',
          sorting: 2,
          nav: {
            roles: ['accountant'],
            types: ['internal'],
          },
        },
      },

      {
        name: 'accountant/corp/offline',
        path: 'accountant/corp/offline',
        component: () => import('@/modules/admin/corpClients/index.vue'),
        props: { contractsType: 'offline' },
        meta: {
          label: 'Корп. клиенты (Оффлайн)',
          icon: 'nav/corp',
          sorting: 3,
          nav: {
            roles: ['admin', 'accountant'],
            types: ['internal'],
          },
        },
      },

      {
        name: 'accountant/azs/online',
        path: 'accountant/azs/online',
        component: () => import('@/modules/accountant/companies/index.vue'),
        props: { companyType: 'azs', contractsType: 'online' },
        meta: {
          label: 'АЗС',
          icon: 'nav/gas-station',
          sorting: 3,
          nav: {
            roles: ['accountant'],
            types: ['internal'],
          },
        },
      },
      {
        name: 'accountant/azs/offline',
        path: 'accountant/azs/offline',
        component: () => import('@/modules/accountant/companies/index.vue'),
        props: { companyType: 'azs', contractsType: 'offline' },
        meta: {
          label: 'АЗС (оффлайн)',
          icon: 'nav/gas-station',
          sorting: 5,
          nav: {
            roles: ['admin', 'accountant', 'support', 'first_line_support', 'sales'],
            types: ['internal'],
          },
        },
      },
      {
        name: 'accountant/company',
        path: 'accountant/:companyType/:contractsType/:companyId',
        component: () => import('@/modules/accountant/company/index.vue'),
        props: true,
      },
      {
        name: 'accountant/company/order',
        path: 'accountant/:companyType/:contractsType/:companyId/:contractId/orders/:orderId',
        component: () => import('@/modules/accountant/order/index.vue'),
        props: true,
      },

      {
        name: 'accountant/statements',
        path: 'accountant/statements',
        component: ControlView,
        props: true,
        meta: {
          label: 'Вх. платежи',
          icon: 'nav/statements',
          sorting: 6,
          nav: {
            roles: ['accountant', 'sales', 'admin', 'support'],
            types: ['internal'],
            items: [
              { label: 'Список', item: 'statements/list' },
              { label: 'Шаблоны', item: 'statements/patterns' },
            ],
          },
        },
        children: [
          {
            name: 'statements/list',
            path: 'list',
            component: () => import('@/modules/StatementsReceiptsPage.vue'),
            props: true,
          },
          {
            name: 'statements/patterns',
            path: 'patterns',
            component: () => import('@/modules/StatementsPatternsPage.vue'),
            props: true,
          },
        ],
      },

      // Поддержка
      {
        name: 'admin/users',
        path: 'admin/users',
        component: ControlView,
        props: true,
        meta: {
          label: 'Пользователи',
          icon: 'nav/users',
          sorting: 1,
          nav: {
            roles: ['admin', 'support'],
            types: ['internal'],
            items: [
              { label: 'Пользователи', item: 'admin/users/users' },
              { label: 'Пользователи ЛК', item: 'admin/users/lk-users' },
              { label: 'SMS приложения', item: 'admin/users/sms-mobile-app' },
              { label: 'SMS кабинета', item: 'admin/users/sms-lk' },
            ],
          },
        },
        children: [
          {
            name: 'admin/users/users',
            path: 'users',
            component: () => import('@/modules/support/users/UsersTab/index.vue'),
            props: true,
          },
          {
            name: 'admin/users/lk-users',
            path: 'lk-users',
            component: () => import('@/modules/support/users/LkUsersTab/index.vue'),
            props: true,
          },
          {
            name: 'admin/users/sms-mobile-app',
            path: 'sms-mobile-app',
            component: () => import('@/modules/support/users/SmsLimitsTab/index.vue'),
            props: (route) => ({
              id: route.params.id,
              title: 'SMS приложения',
              logsType: LogType.MaAuth,
              limitsType: LimitsType.MaAuth,
              routeName: 'sms-mobile-app',
            }),
          },
          {
            name: 'admin/users/sms-lk',
            path: 'sms-lk',
            component: () => import('@/modules/support/users/SmsLimitsTab/index.vue'),
            props: (route) => ({
              id: route.params.id,
              title: 'SMS кабинета',
              logsType: LogType.LkAuth,
              limitsType: LimitsType.LkAuth,
              routeName: 'sms-lk',
            }),
          },
        ],
      },
      {
        name: 'first_line_support/users',
        path: 'first-line-support/users',
        component: () => import('@/modules/support/users/UsersTab/index.vue'),
        props: true,
        meta: {
          label: 'Пользователи',
          icon: 'nav/users',
          sorting: 1,
          nav: {
            roles: ['first_line_support'],
            types: ['internal'],
          },
        },
      },

      {
        path: 'admin/users/users/:userType/:partnerId/:driverId',
        name: 'support/driver',
        component: () => import('@/modules/support/user/index.vue'),
        props: true,
      },
      {
        path: 'admin/users/users/:userType/:phone',
        name: 'support/user',
        component: () => import('@/modules/support/user/index.vue'),
        props: true,
      },

      {
        name: 'support/scripts',
        path: 'support/scripts',
        component: ControlView,
        meta: {
          label: 'Скрипты',
          icon: 'nav/edit',
          sorting: 9,
          nav: {
            roles: ['support'],
            types: ['internal'],
            items: [
              { label: 'Список', item: 'support/scripts/scripts' },
              { label: 'Задачи', item: 'support/scripts/runs' },
            ],
          },
        },
        children: [
          {
            name: 'support/scripts/scripts',
            path: 'scripts',
            component: () => import('@/modules/support/scripts/scripts/index.vue'),
            props: true,
          },
          {
            name: 'support/scripts/script',
            path: 'scripts/:scriptId',
            component: () => import('@/modules/support/scripts/script/index.vue'),
            props: true,
          },
          {
            name: 'support/scripts/runs',
            path: 'runs',
            component: () => import('@/modules/support/scripts/runs/index.vue'),
            props: true,
          },
          {
            name: 'support/scripts/run',
            path: 'runs/:runId',
            component: () => import('@/modules/support/scripts/run/index.vue'),
            props: true,
          },
        ],
      },

      {
        path: 'support/monitoring',
        name: 'support/monitoring',
        component: () => import('@/modules/support/MonitoringPage.vue'),
        meta: {
          label: 'Мониторинг',
          icon: 'nav/monitoring',
          sorting: 8,
          nav: {
            roles: ['support', 'first_line_support'],
            types: ['internal'],
          },
        },
      },
      // Карта АЗС
      {
        path: 'support/map',
        name: 'support/map',
        component: () => import('@/modules/support/map/index.vue'),
        meta: {
          label: 'Карта АЗС',
          icon: 'nav/map-point',
          sorting: 6,
          nav: {
            roles: ['support', 'first_line_support'],
            types: ['internal'],
          },
        },
      },

      // Помощь
      {
        path: 'help',
        name: 'help',
        component: () => import('@/modules/help/index.vue'),
        meta: {
          label: 'Помощь',
          icon: 'nav/info',
          sorting: 9,
          nav: {
            roles: ['admin', 'manager', 'sales', 'support', 'first_line_support'],
            types: ['internal', 'tk', 'taxi', 'aggregator', 'azs'],
          },
        },
      },

      // Адрес по умолчанию
      {
        path: '',
        redirect: ({ params }) => ({ name: 'orders', params }),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  routes,
});

export default router;
