import { Module } from 'vuex';
import { createSimpleMutations } from '@/helpers/vuex';
import { RootState } from '@/store';
import { getPayments } from '@/services/PaymentsService';
import { CompanyReportType, CompanyDepartment, CompanyContract } from '@/services/CompanyService';
import { ROUTE_PARAMS_TYPE } from '@/router';
import { CompanyUserAccess, Payments } from '@/gql/graphql';
import * as actions from './actions';

export interface CabinetState {
  user: AuthUser | null;
  company: Company | null;
  companies: Company[];
  departments: CompanyDepartment[];
  fuel: Fuel[];
  fuelGroups: FuelGroup[];
  regions: Region[];
  payments: Payments;
  isRequestInProgress: boolean;
  errorMessage: string;
  department: CompanyDepartment | null;
}

const cabinet: Module<CabinetState, RootState> = {
  namespaced: true,

  state: (): CabinetState => ({
    user: null,
    company: null,
    companies: [],
    departments: [],
    fuel: [],
    fuelGroups: [],
    regions: [],
    isRequestInProgress: false,
    errorMessage: '',
    payments: {} as Payments,
    department: null,
  }),

  getters: {
    isLoaded: (state: CabinetState) => state.user !== null,
    isDepartment: (state) => Boolean(state.department?.id),
    user: (state: CabinetState) => state.user,
    company: (state: CabinetState) => state.company,
    fuel: ({ fuel }) => fuel || [],
    fuelGroups: ({ fuelGroups }) => fuelGroups || [],
    regions: (state: CabinetState) => state.regions || [],
    needToFeelData: ({ company }: CabinetState): boolean => {
      if (!company) {
        return false;
      }
      const kppOk = company?.companyType?.toUpperCase() === 'ИП' || !!company?.kpp;
      return !(
        kppOk &&
        company.inn &&
        company.ogrn &&
        company.officialAddress &&
        company.contract?.account &&
        company.contract?.bik &&
        company.contract.correspondence
      );
    },

    companySelectOptions({ companies }) {
      return companies.map((company) => {
        const contracts = company.contracts || [];
        if (company.type === 'azs') {
          return {
            label: company.name,
            value: company.id,
            children: contracts.map((contract) => {
              const contractType = contract.type;
              const contractBusinessType = contract.businessType
                ? { b2b: 'B2B', b2c: 'B2C' }[contract.businessType]
                : '';
              const contractNumber = contract.number || 'Номер отсутствует';
              const contractNumberTitle = [contractBusinessType, contractNumber].join(' | ');
              const contractTypeTitle = contractType
                ? { online: 'Онлайн', custom: 'Онлайн', offline: 'Оффлайн' }[contractType]
                : '';
              return {
                label: [contractTypeTitle, contractNumberTitle],
                value: contract.id,
              };
            }),
          };
        }
        return {
          label: company.name,
          value: company.id,
        };
      });
    },

    companyPartnersIds({ companies }) {
      return companies.reduce((result: string[], company) => {
        const ids = company.partners?.map(({ id }) => id) || [];
        result = [...result, ...ids];
        return result;
      }, []);
    },

    companiesTypesById({ companies }) {
      return companies.reduce((result, company) => {
        result[company.id] = company.type;
        company.contracts?.forEach((contract) => {
          result[contract?.id || ''] = company.type;
        });
        return result;
      }, {} as { [key: string]: string });
    },

    companyRouteTypeById({ company }, { companiesTypesById, companyPartnersIds }) {
      return (id: string) => {
        const companyType = companiesTypesById[id] || '';
        const isDepartment = companyPartnersIds.includes(id) || Boolean(company?.partner);
        if (isDepartment && !companyType) {
          return ROUTE_PARAMS_TYPE.department;
        }
        if (companyType === 'azs') {
          return ROUTE_PARAMS_TYPE.station;
        }
        return ROUTE_PARAMS_TYPE.company;
      };
    },

    breadcrumbs({ company }) {
      const rootId = {
        [ROUTE_PARAMS_TYPE.company]: company?.id,
        [ROUTE_PARAMS_TYPE.station]: company?.contract?.id,
      }[ROUTE_PARAMS_TYPE.company];

      const result = [
        {
          title: company?.name || 'Компания',
          urlPath: `/company/${rootId}/orders`,
        },
      ];
      const hasDepartment = company?.partner;
      const hasSubDepartment = company?.partner?.parent;
      if (hasSubDepartment) {
        result.push({
          title: company?.partner?.parent?.name || '',
          urlPath: hasDepartment ? `/department/${company?.partner?.parent?.id}/orders` : '',
        });
      }

      if (hasDepartment) {
        result.push({
          title: company?.partner?.name || '',
          urlPath: '',
        });
      }
      return result;
    },
  },

  mutations: {
    ...createSimpleMutations<CabinetState>([
      'companies',
      'payments',
      'isRequestInProgress',
      'errorMessage',
      'departments',
      'department',
    ]),

    setUser: (state: CabinetState, user: AuthUser | null) => {
      state.user = <AuthUser>user;
    },
    setCompany: (state: CabinetState, company: Company | null) => {
      state.company = <Company>company;
      const companyInList = state.user?.companies?.find(({ id }) => id === company?.id);
      if (companyInList) {
        companyInList.name = company?.name || '';
      }
    },
    updateCompany: (state: CabinetState, company: Company | null) => {
      if (!company) {
        return;
      }
      state.company = { ...state.company, ...company };
    },
    updateInfo: (state: CabinetState, { fuel, fuelGroups, regions }) => {
      state.fuel = fuel;
      state.regions = regions;
      state.fuelGroups = fuelGroups;
    },

    companyReportType: (state, value: CompanyReportType) => {
      if (state.company) {
        state.company.reportType = value;
      }
    },
  },

  actions: {
    ...actions,

    async fetchPayments(
      { commit },
      {
        companyId,
        departmentId,
        page,
        pageSize,
      }: { departmentId: string; companyId: string; page: number; pageSize: number },
    ) {
      commit('isRequestInProgress', true);
      try {
        const response = await getPayments({ companyId, partnerId: departmentId, filter: { page, size: pageSize } });
        const payments = response.cabinet?.company?.payments;
        if (!payments) {
          throw new Error('Не удалось получить список платежей');
        }
        commit('updateCompany', { payments });
        commit('payments', payments);
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : 'Не удалось получить список платежей';
        commit('errorMessage', errorMessage);
      } finally {
        commit('isRequestInProgress', false);
      }
    },
  },
};

export default cabinet;

export type Partner = {
  id: string;
  name: string;
  driversCount: number;
  registeredDriversCount: number;
};

export type CompanyContact = {
  name?: string;
  phone?: string;
  email?: string;
};

export type Company = {
  id: string;
  name: string;
  type: string;
  role: string;
  b2b: boolean;
  payments?: {
    balance: number;
    needMoney: boolean;
  };
  hasDrivers?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  drivers?: any;

  brand?: string;
  companyType?: string;
  officialAddress?: string;
  postAddress?: string;
  inn?: string;
  kpp?: string;
  ogrn?: string;
  contact?: string;
  director?: string;
  phone?: string;
  email?: string;
  status?: string;
  source?: string;
  stat?: {
    departmentsCount: number;
    allDepartmentsCount: number;
    driversCount: number;
    activeDriversCount: number;
  };
  buh?: CompanyContact;
  tech?: CompanyContact;
  contract?: CompanyContract;
  contracts?: CompanyContract[];
  reportType?: 'default' | 'car';
  partner?: CompanyDepartment;
  partners?: {
    id: string;
    name: string;
  }[];
  registered: string;
  cars: {
    count: number;
    items: unknown[];
  };
  userAccess: CompanyUserAccess[];
  simple?: boolean;
};

export type AuthUser = {
  email?: string;
  phone?: string;
  companies: Company[];
};

export type Fuels = {
  fuel: Fuel[];
  fuelGroups: FuelGroup[];
  regions: Region[];
};

export type Fuel = {
  id: string;
  name: string;
  shortName?: string;
  group?: {
    id: string;
    name?: string;
  } | null;
  groupId?: string;
};

export type FuelGroup = {
  id: string;
  name: string;
};

type Region = {
  id: string;
  name: string;
};
